// @flow
import * as React from 'react';
import { useState, Suspense, useEffect } from "react";
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import './Form.css';
import { BoatVals } from './BoatForm';
import { FullScreenLoader } from './Loader';
import { ReactComponent as FinFlag } from './res/flags/4x3/fi.svg';
import { ReactComponent as SweFlag } from './res/flags/4x3/se.svg';
import type { LangState, LangDoc } from "./Lang";
import { LangContext } from './Lang';
import { End } from './End';
import * as Fetch from "whatwg-fetch";
import type { RouterHistory } from "react-router-dom";
import type { Ref } from "react";

type UrlParamsRes = {|
    lang: string,
    boat_id: string,
    order_id: string,
    boat_type: string,
    boat_name: string
|}

function getUrlParams(urlParams: URLSearchParams): UrlParamsRes {
    let entries: UrlParamsRes = {
        lang: "",
        boat_id: "",
        order_id: "",
        boat_type: "",
        boat_name: ""
    };
    for (var pair of urlParams.entries()) {
        entries[pair[0]] = pair[1];
    }
    return entries;
}

export type VerifyResponse = Map<string, string>

async function checkTokens(
    host: string,
    globalState: GlobalState,
    tokens: string[]
): Promise<VerifyResponse> {
    let cleaned = tokens.filter(t => {
        return (t != null
        && t.length > 0)
    })

    if (cleaned.length < 1) {
        throw "No tokens";
    }

    let res: Response = await fetch(host + "/verify", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(cleaned)
    });

    if (res.status === 401)
        throw "Unauthorized"

    var body;
    try {
        body = await res.json();
    } catch {
    }

    let result = new Map();
    // $FlowIgnore
    if (Object.prototype.toString.call(body) === '[object Array]') {
        body = (body ?? []: string[])
        if (body.length !== tokens.length)
            throw new Error("Token request and response have different lengths.")

        tokens.forEach((t, i) => {
            console.log(t, body[i]);
            result.set(t, body[i])
        })
    }

    return result;
}

function wrapTokenPromise(promise: Promise<VerifyResponse>, token: string): Promise<string> {
    return promise.then(async res => {
        let elem = res.get(token);
        if (!elem)
            return "";

        return elem;
    });
}

export function handleInvalidToken(
    promise: Promise<string>,
    ref: {|current: boolean|},
    setPostResult: Promise<PostResult> => any,
    history: RouterHistory
): Promise<any> {
    return promise.catch(() => {
        console.log("REF CURRENT: ", ref.current)
        if (ref.current) {
            setPostResult(Promise.resolve({
                status: 401,
                preCheck: true
            }));
            history.push("/invalid");
        }
    });
}

export type BoatPost = {
    boat_id: string,
    lang: string,
    boat: string,
    boat_type: string,
    make_model: string,
    engine: string,
    length_width: string,
    weight: string,
    other: string
}

export type OrderPost = {
    lang: string,
    boatType: string,
    order_id: string,
    created: string,
    storage: string,
    else_storage: string,
    engine_jobs: string[],
    else_engine: string,
    serve_batteries: bool,
    gas_bottles: string,
    check_fire_ext: bool,
    kg2: string,
    kg3: string,
    kg6: string,
    valve: string,
    remove_mast: bool,
    mast_storage: string,
    rigging: string[],
    empty_bilge: bool,
    septic_emptied: bool,
    clean_water_filter: bool,
    wash_bottom: bool,
    wash_hull: bool,
    wash_deck: bool,
    paint_bottom: bool,
    paint_color: string,
    wax_jobs: string[],
    clean_in: bool,
    water_sys: bool,
    zinc: string[],
    sail_jobs: string[],
    general_other: string
}

export type PostData = {
    boat: ?BoatVals,
    order: ?OrderPost
}

const destHost = 'https://formserver-pnzwxmmkja-lz.a.run.app';
// const destHost = 'http://127.0.0.1:8080';

export function readLocalStorage(name: string): ?any {
    let storage = window.localStorage;
    let item = storage.getItem(name);
    return item ? JSON.parse(item) : null;
}

let langs = new Map<string, LangDoc>();

const BoatForm = React.lazy(() => import('./BoatForm'));
const LiftForm = React.lazy(() => import('./LiftForm'));

export type History = {
    boat: ?BoatVals,
    order: ?any
}

export const null_history = (h: History) => {
    h.boat = null;
    h.order = null;
}

export type GlobalState = {
    langState: LangState,
    history: History
}

export type PostResult = {
    status?: number,
    body?: any,
    preCheck?: boolean
}

export const App: React.ComponentType<void> = () => {
    let [postResult, setPostResult] = useState((null: ?Promise<PostResult>));
    let [tokens, setTokens] = useState(null);

    const queryString = new URLSearchParams(window.location.search);
    let urlParams = getUrlParams(queryString);

    if (urlParams.boat_type === "")
        urlParams.boat_type = "sail_type"

    let langChoice = urlParams.lang;
    if (!langChoice) {
        langChoice = "fi";
    }

    let langState: LangState = {
        langs,
        choice: langChoice
    }

    let initGlobalState: GlobalState = {
        langState,
        history: {
            boat: null,
            order: null,
            boat_name: ""
        }
    }

    let [globalState, ] = useState(initGlobalState);

    if (!tokens) {
        let ts = checkTokens(destHost, globalState, [urlParams.boat_id, urlParams.order_id]);
        setTokens(ts);
    }

    const availLangs = [
        {
            key: "fi",
            label: "Suomi",
            abbrev: "FI",
            icon: <FinFlag className="flag" />
        },
        {
            key: "sv",
            label: "Svenska",
            abbrev: "SV",
            icon: <SweFlag className="flag" />
        },
    ]

    var boatToken, orderToken;

    if (tokens) {
        boatToken = wrapTokenPromise(tokens, urlParams.boat_id)
        orderToken = wrapTokenPromise(tokens, urlParams.order_id)
    }

    let props = {
        initVals: {
            lang: ""
        },
        langs: langs,
        availLangs: availLangs,
        boat_id: urlParams.boat_id,
        path: '/boats',
        globalState,
        setPostResult,
        boatToken
    };

    return (
        <LangContext.Provider value={globalState.langState}>
            <Router>
                <Helmet>
                    <title>D-Marin</title>
                </Helmet>
                <Switch>
                    <Route path='/boat'>
                        <Suspense fallback={<FullScreenLoader />}>
                            <BoatForm {...props}/>
                        </Suspense>
                    </Route>
                    <Route path="/lift">
                        <Suspense fallback={<FullScreenLoader />}>
                            <LiftForm
                                globalState={globalState}
                                langs={langs}
                                availLangs={availLangs}
                                path="/lifts"
                                destHost={destHost}
                                boatType={urlParams.boat_type}
                                boatName={urlParams.boat_name}
                                orderId={urlParams.order_id}
                                setPostResult={setPostResult}
                                orderToken={orderToken}
                                />
                        </Suspense>
                    </Route>
                    <Route path="/end">
                        <End
                            postResult={postResult}
                            globalState={globalState}
                            contactMail={"form@d-marin.fi"}
                            />
                    </Route>
                    <Route path="/ld">
                        <FullScreenLoader />
                    </Route>
                    <Route path="/invalid">
                        <End
                            postResult={postResult}
                            globalState={globalState}
                            contactMail={"form@d-marin.fi"}
                            />
                    </Route>
                </Switch>
            </Router>
        </LangContext.Provider>
    );
}

export { App as default } from './App.js';