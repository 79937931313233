// @flow
import * as React from 'react';
import './Loader.css';

export type LoaderProps = {
  className?: string
};

const Loader: React.ComponentType<LoaderProps> = (props) => {
  return (
  <div className={props.className ?? "loader-box"}>
    <div className="spinner">
      <svg version="1.1" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle className="spinner" cx="50%" cy="50%" r="42%" strokeWidth="8%" pathLength="100" />
      </g>
      </svg>
    </div>
  </div>
  );
}

export type FullScreenLoaderProps = {
  className?: string,
  ...LoaderProps
}

const FullScreenLoader: React.ComponentType<FullScreenLoaderProps> = 
({className, ...props}) => {
  return (
    <div className={`${className ?? ""} full-screen`}>
      <Loader
        {...props}
      />
    </div>
  );
}

export { Loader, FullScreenLoader };