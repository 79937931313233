// @flow
import * as React from 'react';
import { useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useLang } from './Lang.js';
import type { GlobalState, PostResult } from "./App";
import './Form.css';
import { TextField, Select, LangSelect, TextArea } from './FormFields.js';
import { ReactComponent as DMarinLogo } from './res/d-marin-text-logo.svg';
import { handleInvalidToken } from "./App";

type BoatFormProps = {
    globalState: GlobalState,
    initVals: {
        lang: string,
    },
    langs: Map<string, any>,
    availLangs: {
        key: string,
        label: string,
        abbrev: string,
        icon: any
    }[],
    boat_id: string,
    boatToken: ?Promise<string>,
    setPostResult: Promise<PostResult> => void,
    path: string,
}

export class BoatVals {
    boat_id: string = '';
    lang: string = '';
    boat: string = '';
    boat_type: string = '';
    make_model: string = '';
    engine: string = '';
    length_width: string = '';
    weight: string = '';
    other: string = '';
}

export const BoatForm: React.ComponentType<BoatFormProps> = (props) => {
    const history = useHistory();
    const ref = useRef(true);
    let localBoat: ?BoatVals = props.globalState.history.boat;

    let [lang, setLang] = useLang();

    useEffect(() => {
        window.onbeforeunload = (_) => "Are you sure?";

        if (props.boatToken)
            handleInvalidToken(props.boatToken, ref, props.setPostResult, history);
        
        return (() => {
            window.onbeforeunload = null
        });
    }, [])

    const boatTypeOpts = [
        ["sail_type", lang?.boat_form.sail_type],
        ["motor_type", lang?.boat_form.motor_type]
    ];
    let initVals = Object.assign(
        new BoatVals(),
        {
            boat_id: props.boat_id,
            boat_type: boatTypeOpts[0][0],
        }, 
        props.initVals,
        localBoat
    );

    initVals.lang = props.globalState.langState.choice;

    return (
        <>
            <Helmet>
                <title>{`D-Marin | ${lang?.boat_form.register_boat}`}</title>
            </Helmet>
            <Formik
                initialValues={initVals}
                validationSchema={Yup.object({
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    history.push({
                        pathname: '/lift',
                    });
                }}
            >
            {formik => {
                props.globalState.history.boat = formik.values;
                return (
                <div className="bg-container">
                    <Form id="boat-form" className="main-form">
                    <div className="section-container">
                        <LangSelect
                            className="lang-choose"
                            name="lang"
                            langs={props.availLangs}
                            init={props.globalState.langState.choice}
                            breakpoint={630}
                            onChange={e => {
                                formik.handleChange(e);
                                setLang(e.target.value);
                            }}
                        />
                    <div className="section">
                        <DMarinLogo id="dmarin-top-logo" />
                        <div className="header">
                            <h1>{lang?.boat_form.register_boat}</h1>
                        </div>
                        <TextField
                            label={lang?.boat_form.boat}
                            name="boat"
                        />
                        <Select
                            label={lang?.boat_form.type}
                            name="boat_type"
                            options={boatTypeOpts}
                            className="form-select"
                        />
                        <TextField
                            label={lang?.boat_form.make_model}
                            name="make_model"
                        />
                        <TextField
                            label={lang?.boat_form.engine}
                            name="engine"
                        />
                        <TextField
                            label={lang?.boat_form.length_width}
                            name="length_width"
                        />
                        <TextField
                            label={lang?.boat_form.weight}
                            name="weight"
                        />
                        <TextArea
                            label={lang?.boat_form.other}
                            name="other"
                        />
                        <button id="boat-submit" className="submit" type="submit">
                            {lang?.boat_form.proceed}
                            <i className="fa fa-arrow-right"/>
                        </button>
                    </div>
                    </div>
                    </Form>
                </div>
                );
            }}
            </Formik>
        </>
    )
}

export { BoatForm as default } from './BoatForm.js';