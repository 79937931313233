// @flow strict
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import type { GlobalState, PostResult } from './App.js';
import { null_history } from './App.js';
import { useLang } from './Lang.js';
import { Loader } from './Loader';
import './End.css';
// $FlowIgnore
import { ReactComponent as DMarinLogo } from './res/favicon.svg';

type EndPageProps = {
  headerText: React.Node,
  message: React.Node,
  loading: boolean
}

const EndPage: React.ComponentType<EndPageProps> = 
(props) => {
  return (
    <div className="success-page">
      <div className="main-box">
        <div className="logo-container expander">
          <DMarinLogo />
        </div>
        <div className="thank-you">
          <h1>{props.headerText}</h1>
        </div>
        <div className="message">
          {props.loading && (
          <div className="loader-container">
            <Loader className="end-page-loader" />
          </div>
          )}
          <h2 className={props.loading ? " invisible" : ""}>{props.message}</h2>
        </div>
        <div className="expander"></div>
      </div>
    </div>
  );
}

export type EndProps = {|
  globalState: GlobalState,
  postResult: ?Promise<PostResult>,
  contactMail: string
|}

export const End: React.ComponentType<EndProps> = 
({globalState, ...props}) => {
  let [lang, ] = useLang();
  let [postResult, setPostResult] = useState((null: ?PostResult));

  useEffect(() => {
    null_history(globalState.history)
  }, []);

  if (props.postResult)
    props.postResult.then(setPostResult);
  else
    return null;

  const contactMail = (
    <a href={`mailto:${props.contactMail}`}>
      {props.contactMail}
    </a>
  );

  message = (
    <>
      {lang.end.contact_msg + " "}
      {contactMail}
    </>
  );

  var headerText, message: React.Node;
  var loading = false;
  var titleText: string;

  switch (postResult?.status) {
    case null || undefined:
      headerText = lang.end.submitting;
      loading = true;
      break;
    case 200:
      headerText = lang.end.thank_you;
      message = lang.end.msg;
      titleText = lang.end.title_thank_you;
      break;
    case 401:
      headerText = lang.end.unauth_alert;
      titleText = lang.end.title_error;
      break;
    default:
      headerText = lang.end.error_alert;
      titleText = lang.end.title_error;
      break;
  }

  if (postResult?.preCheck) {
    headerText = lang.end.unauth_precheck_alert;
    message = (
      <>
        {lang.end.incorrect_link + " "}
        {lang.end.contact_msg + " "}
        {contactMail}
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`D-Marin | ${titleText ?? ""}`}</title>
      </Helmet>
      <div className="end-result">
        <EndPage
          headerText={headerText}
          message={message}
          loading={loading}
          />
      </div>
    </>
  );
}

export { End as default } from './End.js';