// @flow 
import {useEffect, useState, useRef} from 'react';
import * as React from 'react';
import { useField, useFormikContext, Field } from 'formik';
import './Form.css';
import { ReactComponent as CheckIcon } from './check-box.svg';
import { ReactComponent as RadioIcon } from './radio.svg';

export type SelectProps = {
    pred?: ((any, any) => bool),
    options: [string, any][],
    value?: string,
    onBlur?: ((any) => any),
    className?: string,
    name: string,
    id?: string,
    label?: string,
    onChange?: any => any
}

const Select: React.ComponentType<SelectProps> = ({pred, options, value, ...props}) => {
    const { values } = useFormikContext();
    const [field, , { setValue }] = useField(props);
    const [open, setOpen] = useState(false);
    const popup = useRef(null);

    const predCheck = !pred || pred(field.value, values);

    if (options && predCheck) {
        // move the chosen element to front
        const choice = options.find(opt => opt[0] === field.value);
        const ordered = options.filter(opt => opt[0] !== field.value)
        if (choice) ordered.unshift(choice);

        const opts = ordered.map((opt, i) => {
            let choiceClass = i === 0 ? " choice" : "";
            let choiceMark = i === 0
                ? <i className="fa fa-check"></i>
                : null;
            return (
            <React.Fragment key={opt[0]}>
                {i !== 0
                ? <span className="select-sep"></span>
                : null}
                <button 
                className={"option" + choiceClass}
                type="button"
                tabIndex={0}
                onClick={(e) => {                                        
                    if (props.onChange) {
                        props.onChange({
                            target: {
                                name: props.name,
                                id: props.id,
                                type: null,
                                value: opt[0],
                                options: options,
                            }
                        });
                    }
                    setValue(opt[0]);
                    setOpen(false)
                }}
                >
                <div className="opt-box">
                    {opt[1]}
                    <span className="ibox">
                        {choiceMark}
                    </span>
                </div>
                </button>
            </React.Fragment>
        );
        })
        return (
            <div
                {...props}
                autoFocus={true}
                style={{outline: "none"}}
                className={`form-select ${props.className ? props.className : ""}`}
                >
                <label htmlFor={props.name} className="form-label">{props.label}</label>
                <button 
                    type="button"
                    className="select-open"
                    tabIndex={open ? -1 : 0}
                    onClick={() => {
                        setOpen(!open);
                        if (popup.current) {
                            popup.current.focus();
                        }
                    }} 
                    >
                    <div className="opt-box">
                        {field.value 
                            ? options.find(opt => 
                                opt[0] === field.value)?.[1]
                            : ""
                        } 
                        <span className="ibox">
                            <i className="fa fa-angle-down" />
                        </span>
                    </div>
                </button>
                <div 
                    tabIndex={open ? 0 : -1}
                    ref={popup}
                    className="select-popup"
                    onBlur={(e) => {
                        if (props.onBlur)
                            props.onBlur(e);
                        if (!e.currentTarget.contains(e.relatedTarget)) {
                            setOpen(false)
                        }
                    }}
                    >
                    {open
                    ? opts
                    : null}
                </div>
            </div>
        );
    } else {
        return null;
    }
}

type TextFieldProps = {|
    ...React.ElementProps<React.ElementType>,
    label?: string,
    pred?: (any, any) => boolean,
    errorPred?: (any, any) => boolean,
    name: string
|}

const TextField: React.ComponentType<TextFieldProps> =
({label, pred, errorPred, ...props}) => {
    const { values, errors } = useFormikContext();
    const [field, meta, helpers] = useField(props);
    const errorClass = meta.error ? " error" : "";

       if (!pred || pred(field.value, values)) {
        return (
            <div htmlFor={props.name} className="text-input-form-label">{label}
            <div className="text-input-container">
                <div 
                    className={"text-input" + errorClass + (props.className ? " " + props.className : "")}
                    tabIndex={0}
                    contentEditable
                    onInput={(e: SyntheticInputEvent<Element>) => {
                        let woBreak = e.target.innerText?.replace("\n", "")
                        helpers.setValue(woBreak);
                    }}
                    {...props}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                        }
                    }}
                    suppressContentEditableWarning={true}
                    >
                    {meta.touched ? null : meta.initialValue}
                </div>
                <span className="text-input-border">
                    <span className="text-input-glow" />
                </span>
                {meta.touched && meta.error && (!errorPred || errorPred(meta.error, errors)) 
                    ? ( <div className="field-error">{meta.error}</div>) 
                    : null}
            </div>
            </div>
        );
    } else {
        return null;
    }
};

type ErrorMessageProps = {
    msg: (any, any) => any[]
}

const ErrorMessage: React.ComponentType<ErrorMessageProps> = ({msg, ...props}) => {
    const { values, errors } = useFormikContext();
    let messages = msg(values, errors);
    if (messages.length > 0) {
        return (
            <ul {...props} className="error-msg">{messages.map((ms, i) => 
                <li key={i} className="error-msg-part">{ms}</li>
            )}</ul>
        );
    }

    return null;
}

const TextArea = (({pred, label, ...props}) => {
    const ctx = useFormikContext();
    const [field, meta, helpers] = useField(props);
    const border = useRef(null);

    if (pred && !pred(field.value, ctx.values))
        return null;

    return (
        <label className="form-label">
            {label}
            <div className="text-area-container">
            <div 
                {...field}
                {...props}
                name={props.name}
                id={props.id}
                className="text-area" 
                contentEditable={true}
                suppressContentEditableWarning={true}
                onInput={e => {
                    helpers.setValue(e.target.innerText)
                    if (props.onChange) {
                        props.onChange(e);
                    }
                    if (props.onInput) {
                        props.onInput(e);
                    }
                }}
                >
                {meta.initialValue}
            </div>
            <span className="text-area-border" ref={border}>
                <span className="text-input-glow" />
            </span>
            </div>
        </label>
    );
}) 

const CheckBox: React.ComponentType<any> = ({pred, options, isRadio, ...props}) => {
    const ctx = useFormikContext();
    const [field, , ] = useField({type: 'checkbox', ...props});
    const [checked, setChecked] = useState(field.checked);

    useEffect(() => {
        setChecked(field.checked);
    }, [field.checked])

    if (pred && !pred(field.value, ctx.values)) 
        return null;

    const classes = "check-entry" + (checked ? " checked" : "");
    const className =  classes + (props.className ? ` ${props.className}` : "");
    
    const icon = isRadio ? <RadioIcon /> : <CheckIcon />

    return (
        <label 
        className={checked ? " checked" : ""}>
            <Field {...props} 
                type={isRadio ? "radio" : "checkbox"}
                />
            <div 
                {...props}
                checked={checked}
                className={className}
                tabIndex={props.disabled ? -1 : 0}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        e.target.previousSibling.click();
                    }
                }}
                >
                <span 
                    className="check-icon" 
                    >
                    {icon}
                </span>
                <span className="form-label check-label">
                    {props.label}
                </span>
            </div>
        </label>
    );
}

type CheckGroupProps = {
    className?: string,
    label?: string,
    richLabel?: any,
    pred?: (any, any) => bool,
    options: [string, string, bool][] | [string, string][],
    bigLabel?: string,
    name: string
}

const CheckGroup: React.ComponentType<CheckGroupProps> = 
({pred, options, richLabel, ...props}) => {
    const { values } = useFormikContext();
    const [field, , ] = useField(props);
    
    const hasAll  = <T>(arr: T[], elems: [T, any, any][]) => 
        elems.reduce((acc, val) => acc && arr.includes(val[0]), true)
    
    const optBoxes = options.map((opt, i) => {
        let [key, label] = opt;
        let cond = opt[2] ?? false;

        // allows for checkboxes that are active only when the one above is
        let enabled = !cond || i === 0 || hasAll(field.value, options.slice(0, i))

        if (true) {
            return (
                <CheckBox 
                    value={key}
                    name={props.name}
                    disabled={!enabled}
                    className="checkgroup-box"
                    label={label}
                    key={key}
                />
            );
        }
        return null;
    });

    if (pred && !pred(field.value, values))
        return null;

    return (
        <div {...props} 
        className={props.className 
            ? `checkgroup ${props.className}`
            : "checkgroup"}
            >
            {props.label ? <h4>{props.label}</h4> : null}
            {richLabel}
            {optBoxes}
        </div>
    );
}

const RadioGroup: React.ComponentType<CheckGroupProps> = ({pred, options, ...props}) => {
    const { values } = useFormikContext();
    const [field] = useField(props);

    const optBoxes = options.map(([key, label]) => (
        <CheckBox 
            {...props}
            isRadio
            key={key}
            value={key}
            type="radio" 
            className="radiogroup-radio"
            label={label}
        />
    ));

    if (pred && !pred(field.value, values))
        return null;

    return (
        <div className="radiogroup" role="group">
            {props.label ? <h4>{props.label}</h4> : null}
            {optBoxes}
        </div>
    );
}

type LangProps = {
    init: string,
    langs: {
        key: string,
        label: string,
        abbrev: string,
        icon: any
    }[],
    breakpoint: number
}

export const LangSelect: React.ComponentType<LangProps> = ({init, langs, breakpoint, ...props}) => {
    function calcOpts(width) {
        return langs.map(({key, label, abbrev, icon}) => 
            [key, (<>
                <div className="flag-container">
                    {icon}
                </div>
                {width > breakpoint ? label : abbrev}
            </>)]
        )
    }
    let [options, setOptions] = useState(calcOpts(window.innerWidth));

    let lastCalc = {width: window.innerWidth}

    useEffect(() => {
        window.addEventListener('resize', () => {
            let iw = window.innerWidth;
            let bpCross = (iw - breakpoint) * (lastCalc.width - breakpoint);
            if (bpCross < 0) {
                lastCalc.width = iw;
                setOptions(calcOpts(iw));
            }
        });
    }, []);

    return (
        <Select className="lang-choose"
            options={options}
            init={init}
            {...props}
        />
    );
}

export { TextField, Select, CheckGroup, TextArea, RadioGroup, ErrorMessage, CheckBox }
