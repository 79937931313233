// @flow
import * as React from 'react';
import { useState, createContext, useContext } from 'react';

const boat_form_default = {
	register_boat: "",
	boat: "",
	engine: "",
	length_width: "",
	make_model: "",
	motor_type: "",
	other: "",
	sail_type: "",
	type: "",
	weight: "",
  proceed: ""
}
export type LangBoat = typeof boat_form_default;

const lift_form_default =  {
	check_fire_ext: "",
	clean_in: "",
	clean_water_filter: "",
	closed: "",
	created: "",
	disconnect_batteries: "",
	deck: "",
	drive_clutch: "",
	drive_shaft: "",
	else_engine: "",
	empty_bilge: "",
	empty_septic: "",
	engine: "",
	engine_jobs: "",
	fire_ext_amount: "",
	full_rig: "",
	gas_bottles: "",
	gas_bottles_header: "",
	general_other: "",
	glykol: "",
	hull: "",
	in: "",
	in_cold: "",
	in_warm: "",
	kg2: "",
	kg3: "",
	kg6: "",
	label: "",
	lift_header: "",
	maintenance: "",
	mast_sail_header: "",
	mast_storage: "",
	mast_up: "",
	misc_jobs: "",
	must_be_int: "",
	must_be_num: "",
	must_be_pos: "",
	open: "",
	out: "",
	paint_bottom: "",
	paint_color: "",
	paint_wax_header: "",
	prior_lift: "",
	else_storage: "",
	remove_mast: "",
	rig_sails: "",
	rigging: "",
	sail_jobs: "",
	septic_emptied: "",
	serve_batteries: "",
	service: "",
	storage: "",
	storage_header: "",
	top: "",
	valve: "",
	wash: "",
	wash_bottom: "",
	wash_deck: "",
	wash_header: "",
	wash_hull: "",
	water_sys: "",
	wax_jobs: "",
	wax_info: "",
	zinc: "",
	submit: ""
}
export type LangLift = typeof lift_form_default;

const lang_end_default = {
  thank_you: "",
  msg: "",
	unauth_alert: "",
	unauth_precheck_alert: "",
	incorrect_link: "",
	error_alert: "",
	contact_msg: "",
	title_thank_you: "",
	title_error: "",
	submitting: ""
}
export type LangEnd = typeof lang_end_default;

const lang_doc_default = {
  boat_form: boat_form_default,
  lift_form: lift_form_default,
  end: lang_end_default
}
export type LangDoc = typeof lang_doc_default;

export type LangState = {
  langs: Map<string, LangDoc>,
  choice: string
}

export const LangContext: React.Context<LangState> = 
createContext<LangState>({
  langs: new Map<string, LangDoc>(),
  choice: ""
});

async function fetchLang(choice: string): Promise<LangDoc> {
    switch(choice) {
    case "fi": {
        let res = await import("./langs/fi.js");
        return res.strings;
    }
    case "sv": {
        let res = await import("./langs/sv.js");
        return res.strings;
    }
		default:
			console.log(Error("Language not implemented: " + choice));
    	return lang_doc_default;
    }
}

export const useLang = (): [LangDoc, string => any] => {
  let ls = useContext(LangContext);
  let initLang = ls.langs.get(ls.choice);
  let [lang, setLang] = useState(initLang ?? lang_doc_default);

  let setLangFetch = (choice: string) => {
    let ld = ls.langs.get(choice);
    if (ld != null) setLang(ld)
    else fetchLang(choice).then(l => {
			ls.langs.set(choice, l);
			setLang(l);
		})
    ls.choice = choice
  }

  if (initLang == null) {
		setLangFetch(ls.choice);
	}

  return [lang, setLangFetch];
}
